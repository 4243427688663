<template>
  <article class="ticket" :class="{ sticky: sticky }">
    <header class="ticket__wrapper">
      <div class="ticket__header tex-left flex-col items-start">
        <div class="progress-gradient h-2 has-border-radius w-full"></div>
        <p class="text-2xl font-bold mt-3">Pago #{{ $route.params.idPago }}</p>
      </div>
    </header>
    <div class="ticket__divider">
      <div class="ticket__notch"></div>
      <div class="ticket__notch ticket__notch--right"></div>
    </div>
    <div class="ticket__body pt-0 pt-2">
      <section class="ticket__section text-left">
        <p class="has-text-grey">Fecha del pago</p>
        <p class="mt-0">
          {{ pago.fecha_contable.date | moment("DD-MM-YYYY") }}
        </p>
        <p class="has-text-grey">Forma de pago</p>
        <p class="mt-0">{{ pago.instrumento_monetario.nombre }}</p>
        <p class="has-text-grey">Moneda</p>
        <p class="mt-0">{{ pago.moneda.nombre }}</p>
      </section>
      <section class="ticket__section text-left" v-if="hasDisposicion">
        <p class="has-text-grey">Folio de la disposición</p>
        <p class="mt-0">
          <preview :id="disposicion.id" tipo="credito"
            >#{{ disposicion.idToShow }}</preview
          >
        </p>
        <p v-if="disposicion.linea_credito_id" class="has-text-grey">
          Folio de la línea
        </p>
        <p v-if="disposicion.linea_credito_id" class="mt-0">
          <preview :id="disposicion.linea_credito_id" tipo="linea"
            >#{{ disposicion.linea_credito_id }}</preview
          >
        </p>
        <p class="has-text-grey">Moneda de la disposición</p>
        <p class="mt-0">{{ disposicion.moneda }}</p>
        <p v-if="disposicion.contrato" class="has-text-grey">Contrato</p>
        <p v-if="disposicion.contrato" class="mt-0">
          {{ disposicion.contrato }}
        </p>
      </section>
    </div>
    <footer class="ticket__footer flex-col">
      <div class="flex items-center justify-between mb-4">
        <span>Monto total</span>
        <span class="text-right">
          {{ pago.cantidad_pagada | currency }}
          <span class="text-sm">{{ pago.moneda.clave }}</span>
        </span>
      </div>
      <b-button type="is-primary" @click="printTicket"
        >Imprimir ticket</b-button
      >
    </footer>
  </article>
</template>

<script>
export default {
  name: "Ticket",
  props: {
    sticky: {
      type: Boolean,
      default: false
    },
    pago: Object,
    documento: Object
  },
  methods: {
    //Manda llamar a API para que regrese pdf en base64 y mostralro
    printTicket() {
      this.$store.dispatch("pagos/getTicket", this.$route.params.idPago);
    }
  },
  computed: {
    hasDisposicion() {
      if (this.pago.parent.simulaciones) {
        return this.pago.parent.simulaciones.length > 0 ? true : false;
      } else if (this.pago.origen.hasOwnProperty("credito_core_id")) {
        return true;
      }
      return false;
    },
    disposicion() {
      if (this.pago.origen.hasOwnProperty("credito_core_id")) {
        return {
          id: this.pago.parent.id,
          idToShow: this.idToShow(this.pago.parent),
          linea_credito_id: this.pago.lineaCredito.id,
          moneda: this.pago.origen.moneda.nombre,
          contrato: this.pago.lineaCredito.contrato
            ? this.pago.lineaCredito.contrato.numero
            : "N/A"
        };
      } else if (
        this.pago.parent.simulaciones &&
        this.pago.parent.simulaciones.length > 0
      ) {
        return {
          id: this.pago.parent.simulaciones[0].id,
          idToShow: this.pago.parent.simulaciones[0].creditos_autorizados_id,
          linea_credito_id:
            this.pago.parent.simulaciones[0].lineas_credito.length > 0
              ? this.pago.parent.simulaciones[0].lineas_credito[0].id
              : null,
          moneda: this.pago.parent.simulaciones[0].moneda.nombre,
          contrato:
            this.pago.parent.simulaciones[0].lineas_credito.length > 0
              ? this.pago.parent.simulaciones[0].lineas_credito[0]
                  .contrato_linea
                ? this.pago.parent.simulaciones[0].lineas_credito[0]
                    .contrato_linea.numero
                : null
              : null
        };
      }
      return {};
    }
  }
};
</script>

<style lang="scss">
$cutout-size: 1.5rem;
$divider: var(--white-ter);
.ticket {
  &.sticky {
    position: sticky;
    top: 6rem;
  }
  display: grid;
  grid-template-rows: auto 1fr auto;
  &__header,
  &__body,
  &__footer {
    padding: 1rem;
    background-color: var(--white);
    box-shadow: 0 7px 20px 0 rgba(24, 50, 115, 0.2);
  }
  &__header {
    font-size: 1.5rem;
    border-bottom: none;
    box-shadow: none;
  }
  &__wrapper {
    box-shadow: 0 7px 20px 0 rgba(24, 50, 115, 0.2);
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    overflow: hidden;
  }
  &__divider {
    position: relative;
    height: $cutout-size;
    background-color: var(--white);
    margin-left: ($cutout-size / 2);
    margin-right: ($cutout-size / 2);
    &::after {
      content: "";
      position: absolute;
      height: 50%;
      width: 100%;
      left: 0;
      top: 0;
      border-bottom: 4px dashed $divider;
    }
  }
  &__notch {
    position: absolute;
    left: ($cutout-size / 2) * -1;
    width: $cutout-size;
    height: $cutout-size;
    overflow: hidden;
    &::after {
      content: "";
      position: relative;
      display: block;
      width: ($cutout-size * 2);
      height: ($cutout-size * 2);
      right: 100%;
      top: -50%;
      border: ($cutout-size / 2) solid var(--white);
      border-radius: 50%;
      box-shadow: 0 7px 20px 0 rgba(24, 50, 115, 0.12);
    }
    &--right {
      left: auto;
      right: ($cutout-size / 2) * -1;
      &::after {
        right: 0;
      }
    }
  }
  &__body {
    border-bottom: none;
    border-top: none;
    & > * + * {
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      border-top: 3px solid $divider;
    }
  }
  &__section {
    & > * + * {
      margin-top: 0.25rem;
    }
  }
  &__header,
  &__footer {
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
  }
  &__footer {
    border-top: 4px dashed $divider;
    border-radius: 0 0 11px 11px;
  }
}
</style>
